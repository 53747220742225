import { Card, Row, Col, Modal, Button, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Grid, _ } from "gridjs-react";
import { useEffect, useState } from "react";

import * as Components from "./../../../components/Components";
import LocalStorage from "./../../../libraries/LocalStorage";

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import Rest from "./../../../libraries/Rest";
import Alert from "../../../libraries/Alert";

function EmployeeList() {
    const rest = new Rest();
    const navigate = useNavigate();
    const localStorage = new LocalStorage();
    const roleName = localStorage.getItem('role_name')?.toLowerCase();
    const allowedRole = 'super_admin'; // static role validation (code: VL01)

    const endpoint = `/employees`;
    const rootPath = `/employees`;

    /**
     * State for open modal
     */
    const [show, setShow] = useState(false);
    const closeImportModal = () => setShow(false);
    const showImportModal = () => setShow(true);

    const [companyIdForSalary, setCompanyIdForSalary] = useState();
    const [showImportSalary, setShowImportSalary] = useState(false);
    const closeImportSalaryModal = () => setShowImportSalary(false);
    const showImportSalaryModal = () => setShowImportSalary(true);

    const [showPPH, setShowPPH] = useState(false);
    const closeUpdatePPHModal = () => setShowPPH(false);
    const showUpdatePPHModal = () => setShowPPH(true);
    const [pphType, setPphType] = useState('');

    /**
     * Global functions
     */
    const generateOptions = (data, value, label, type = null) => {
        let options = [];
        for (let i = 0; i < data.length; i++) {
            let _label = data[i][label] !== undefined ? data[i][label] : null;
            if (type === 'salary_type' || type === 'marital_status') {
                _label += ` (${data[i]['label_id']})`;
            } else if (
                type === 'department'
                || type === 'position'
                || type === 'employee_type'
            ) {
                _label += ` (${data[i]['name']})`;
            } else if (type === 'bank_code') {
                _label += ` (${data[i]['description']})`;
            }

            options.push({
                value: data[i][value] !== undefined ? data[i][value] : null,
                label: _label
            });
        }
        return options;
    }

    /**
     * State for input data
     */
    const [companyInModal, setCompanyInModal] = useState();
    const [companies, setCompanies] = useState([]);
    const [companyId, setCompanyId] = useState();
    const [departmentCodes, setDepartmentCodes] = useState([]);
    const [positionCodes, setPositionCodes] = useState([]);
    const [employeeTypeCodes, setEmployeeTypeCodes] = useState([]);
    const [ptkp, setPtkp] = useState([]);
    const [salaryTypes, setSalaryTypes] = useState([]);
    const [pphTypes, setPphTypes] = useState([]);
    const [bankCodes, setBankCodes] = useState([]);
    const [maritalStatus, setMaritalStatus] = useState([]);
    const [dataImported, setDataImported] = useState([]);

    const getCompanies = async () => {
        let res = await rest.get(`/companies?page=1&limit=100`);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;
            const options = generateOptions(res, 'id', 'name');

            // if (options.length > 0) setCompanyInModal(options[0]?.value);
            setCompanies(options);

            /**
             * Set default company if user not super_admin
             */
            if (roleName !== allowedRole) {
                const companyName = localStorage.getItem('company_name');
                for (let i = 0; i < options.length; i++) {
                    if (options[i]?.label?.toLowerCase() === companyName?.toLowerCase()) {
                        setCompanyInModal(options[i]?.value);
                        setCompanyIdForSalary(options[i]?.value);
                        await getDepartments(options[i]?.value);
                        await getEmployeeTypes(options[i]?.value);
                        await getBankCodes(options[i]?.value);
                        break;
                    }
                }
            }
        }
    }

    const getStaticDatas = async () => {
        let res = await rest.get(`/statics`);
        if (res?.data?.data !== undefined) {
            res = res.data.data;

            setPtkp(generateOptions(res.ptkp, 'value', 'label_id'));
            setSalaryTypes(generateOptions(res.salary_type, 'value', 'value', 'salary_type'));
            setPphTypes(generateOptions(res.pph_type, 'value', 'value', 'pph_type'));
            setMaritalStatus(generateOptions(res.marital_status, 'value', 'value', 'marital_status'));
        }
    }

    const getDepartments = async (companyId) => {
        let res = await rest.get(`/companies/departments/${companyId}?page=1&limit=100`);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;

            const options = generateOptions(res, 'id', 'code', 'department');
            setDepartmentCodes(options);

            getPositions(companyId, options.length > 0 ? options[0].value : null);
        }
    }

    const getEmployeeTypes = async (companyId) => {
        let res = await rest.get(`/companies/employee_types/${companyId}?page=1&limit=100`);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;
            setEmployeeTypeCodes(generateOptions(res, 'id', 'code', 'employee_type'));
        }
    }

    const getPositions = async (companyId = null, departmentId) => {
        if (!companyId) companyId = companyInModal;

        let res = await rest.get(`/companies/positions/${companyId}/?page=1&limit=100&department_id=${departmentId}`);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;
            setPositionCodes(generateOptions(res, 'id', 'code', 'position'));
        }
    }

    const getBankCodes = async (companyId = null) => {
        let res = await rest.get(`/companies/banks/${companyId}?page=1&limit=100`);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;
            setBankCodes(generateOptions(res, 'id', 'bank_code', 'bank_code'));
        }
    }

    const goToPage = (id, type) => {
        if (type === 'edit') {
            navigate(`${rootPath}/${id}`);
        } else if (type === 'salary_components') {
            navigate(`${rootPath}/salary_components/${id}`);
        } else if (type === 'reset_device') {
            Alert.showConfirm({
                url: `${endpoint}/reset_device/${id}`,
                method: 'GET',
            }).then((result) => {
                if (result.isConfirmed) {
                    if (result?.value?.success) {
                        Alert.showMessage('Success', 'success', result?.value?.data?.data?.detail);
                        navigate(rootPath);
                    } else {
                        Alert.showMessage('Failure', 'error', result?.value?.message);
                    }
                }
            });
        }
    }

    const updatePayload = async (field, value) => {
        if (field === 'companyInModal') {
            setCompanyInModal(value);

            await getDepartments(value);
            await getEmployeeTypes(value);
            await getBankCodes(value);
        } else if (field === 'department_id') {
            await getPositions(null, value);
        } else if (field === 'pph_type') {
            setPphType(value);
        } else if (field === 'companyIdForSalary') {
            setCompanyIdForSalary(value);
        } else {
            setCompanyId(value);
        }
    }

    const removeItem = async (id) => {
        Alert.showConfirm({
            url: `${endpoint}/${id}`,
            method: 'DELETE',
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    navigate(rootPath);
                } else {
                    Alert.showMessage('Failure', 'error', result?.value?.message);
                }
            }
        });
    }

    /**
     * Action handlers
     */
    const downloadTemplate = () => {
        /**
         * Defined attributes and datas
         */
        var data = [
            {
                nik: "12345",
                department_code: "DEP001",
                position_code: "POS01",
                employee_type_code: "ET01",
                ptkp: 'Tk/0',
                salary_type: 'ST01',
                pph_type: 'nett',
                email: 'employee_test01@email.com',
                password: '12345',
                first_name: 'First-name',
                middle_name: 'Middle-name',
                last_name: 'Last-name',
                is_active: true,
                start_date: '2024-01-01',
                end_date: '2025-01-01',
                birth_place: '',
                birth_date: '',
                gender: 'M',
                marital_status: 'MS1',
                phone: '',
                emergency_phone: '',
                emergency_name: '',
                current_address: '',
                identity_address: '',
                bank_code: '',
                bank_city: '',
                account_name: '',
                account_number: '',
            }
        ];
        let header = [
            "ID Employee",
            "Department Code",
            "Position Code",
            "Employee Type Code",
            "PTKP",
            "Salary Type",
            "PPH Type",
            "Email",
            "Password",
            "First Name",
            "Middle Name",
            "Last Name",
            "is_active",
            "Start Date",
            "End Date",
            "Birth Place",
            "Birth Date",
            "Gender",
            "Marital Status",
            "Phone",
            "Emergency Phone",
            "Emergency Name",
            "Current Address",
            "Identity Address",
            "Bank Code",
            "Bank City",
            "Account Name",
            "Account Number",
        ];

        /**
         * XLSX configurations
         */
        const ws = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [header]);
        XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        /**
         * Generate file
         */
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true });
        const finalData = new Blob([excelBuffer], { type: 'application/octet-stream' });

        /**
         * Download file automatically
         */
        saveAs(finalData, "Import-employee-example.xlsx");
    }

    const handleFileUpload = (e, type = null) => {
        if (e.target.files.length <= 0) return false; // skip process

        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const workbook = XLSX.read(event.target.result, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const sheetData = XLSX.utils.sheet_to_json(sheet);

            /**
             * Formatting payload
             */
            let payloads = [];
            for (let i = 0; i < sheetData.length; i++) {
                const value = sheetData[i];

                let obj = {}
                for (let j = 0; j < Object.keys(value).length; j++) {
                    const key = Object.keys(value)[j]?.replace(/ /g, '_')?.toLowerCase();
                    obj[key] = value[Object.keys(value)[j]];
                }

                if (type?.toLowerCase() === 'salary') {
                    obj['company_id'] = companyIdForSalary;
                } else {
                    obj['company_id'] = companyInModal;
                }
                payloads.push(obj);
            }
            setDataImported(payloads);
        };

        reader.readAsArrayBuffer(file);
    };

    const doImport = async () => {
        if (dataImported.length <= 0) return Alert.showMessage('Warning', 'warning', 'You didnt upload any file yet');
        if (companyInModal === '' || companyInModal === undefined) return Alert.showMessage('Warning', 'warning', 'You didnt choose company yet');

        /**
         * Change input field name only for necessary field
         */
        for (let i = 0; i < dataImported.length; i++) {
            dataImported[i].nik = dataImported[i].id_employee;
            delete dataImported[i].id_employee;
        }

        /**
         * Submit action
         */
        Alert.showConfirm({
            url: `/employees/migrate`,
            method: 'POST',
            data: dataImported,
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    setShow(false);
                    navigate(rootPath);
                } else {
                    const errLists = result?.value?.data?.data?.failure;
                    let errMessages = '';
                    if (errLists !== undefined && errLists.length > 0) {
                        for (let i = 0; i < errLists.length; i++) {
                            errMessages += `${errLists[i]?.message} in NIK: ${errLists[i]?.nik}<br />`;
                        }
                    }

                    /**
                     * Get error messages when the response error is nested
                     */
                    if (!errMessages && result?.value?.data?.error?.details !== undefined) {
                        const error = result.value.data.error.details;
                        for (let i = 0; i < error.length; i++) {
                            if (error[i]?.path?.length >= 2) {
                                let _errmsg = error[i]?.message?.split("is");
                                let _msg = _errmsg.length > 0 ? _errmsg[(_errmsg.length - 1)] : 'requied'; // set default value as well

                                errMessages += `Field ${error[i]?.path[1]} in row ${error[i]?.path[0]} is ${_msg}`
                            }
                        }
                    }

                    Alert.showMessage('Failure', 'error', errMessages ? errMessages : 'Something went wrong when uploading data');
                }
            }
        });
    }

    const doUpdatePPH = async () => {
        if (companyInModal === '' || companyInModal === undefined) return Alert.showMessage('Warning', 'warning', 'You didnt choose company yet');
        if (pphType === '' || pphType === undefined) return Alert.showMessage('Warning', 'warning', 'You didnt choose PPH Type yet');

        Alert.showConfirm({
            url: `/employees/update_all_pph_type`,
            method: 'POST',
            data: {
                company_id: companyInModal,
                pph_type: pphType
            },
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    setShowPPH(false);
                } else {
                    Alert.showMessage('Failure', 'error', result?.value?.data?.message);
                }
            }
        });
    }

    const downloadTemplateSalaryComponent = async () => {
        if (!companyIdForSalary) return Alert.showMessage('Warning', 'warning', 'You didnt select any company yet!');
        Alert.showLoading();

        // get all employees by company
        let _emp = await rest.get(`/employees?page=1&limit=1000000&companyId=${companyIdForSalary}`);
        _emp = _emp?.data?.data?.data ?? [];

        // get all salary component by company
        let _salary_component = await rest.get(`/companies/salary_components/${companyIdForSalary}?page=1&limit=1000`);
        _salary_component = _salary_component?.data?.data?.data ?? [];
        Alert.close();

        // preparing data
        let header = [
            "ID",
            "ID Employee",
            "Employee Name",
            "Salary Component ID",
            "Salary Component Name",
            "Total",
            "Type",
            "Remark"
        ];
        let data = [];
        for (let i = 0; i < _emp.length; i++) {
            let row = {
                id: _emp[i]?.id,
                nik: _emp[i]?.nik,
                name: `${_emp[i]?.first_name ?? ''} ${_emp[i]?.middle_name ?? ''} ${_emp[i]?.last_name ?? ''}`,
                salary_component_id: null,
                salary_component_name: null,
                value_amount: 0,
                type: "T1", // as default
                remark: ""
            }
            for (let j = 0; j < _salary_component.length; j++) {
                data.push({
                    ...row,
                    salary_component_id: _salary_component[j].id,
                    salary_component_name: _salary_component[j].name,
                });
            }
        }

        /**
         * XLSX configurations
         */
        const ws = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [header]);
        XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        /**
         * Generate file
         */
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true });
        const finalData = new Blob([excelBuffer], { type: 'application/octet-stream' });

        /**
         * Download file automatically
         */
        saveAs(finalData, "Import-employee-salary-component-format.xlsx");
    }

    const doImportSalaryComponent = async () => {
        // preparing data to upload
        let data = [];
        for (let i = 0; i < dataImported.length; i++) {
            // skip if total <= 0
            if (parseInt(dataImported[i]?.total) <= 0) continue;

            // populating data
            data.push({
                employee_id: dataImported[i]?.id,
                salary_component_id: dataImported[i]?.salary_component_id,
                type: dataImported[i]?.type,
                value_amount: parseInt(dataImported[i]?.total),
                remark: dataImported[i]?.remark,
            });
        }

        // perform to upload
        Alert.showConfirm({
            url: `/employees/salary_components/insert_bulk`,
            method: 'POST',
            data,
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    setShowPPH(false);
                } else {
                    Alert.showMessage('Failure', 'error', result?.value?.data?.message);
                }
            }
        });
    }

    useEffect(() => {
        getCompanies();
        getStaticDatas();
    }, []);

    return (
        <div>
            <Card className="bg-white">
                <Card.Header className="bg-primary">
                    <Card.Title as="label" className="fs-sm fw-medium mb-1 text-white">Employee List</Card.Title>
                </Card.Header>
                <Card.Body className="bg-white">
                    {roleName?.toLowerCase() === allowedRole && <Row>
                        <Col sm="3">
                            <Components.Select
                                label="Company"
                                name="companyId"
                                defaultValue={companyId}
                                change={updatePayload}
                                options={companies}
                            />
                        </Col>
                    </Row>}
                    <Link to={`${rootPath}/add`} className="btn btn-primary btn-sm">
                        <i className="ri-add-line"></i> Create Data
                    </Link>
                    &nbsp;
                    <Button className="btn btn-info btn-sm text-white" onClick={showUpdatePPHModal}>
                        <i className="ri-upload-line"></i> Update PPH Type
                    </Button>
                    &nbsp;
                    <Button className="btn btn-success btn-sm" onClick={showImportModal}>
                        <i className="ri-upload-line"></i> Import Data
                    </Button>
                    &nbsp;
                    <Button className="btn btn-success btn-sm" onClick={showImportSalaryModal}>
                        <i className="ri-upload-line"></i> Import Salary Component
                    </Button>

                    {/* Modal import salary component */}
                    <Modal show={showImportSalary} onHide={closeImportSalaryModal} size="md">
                        <Modal.Header closeButton>
                            <Modal.Title>Import Salary Component</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="alert alert-info">
                                You can import salary components for all employees with import methods, <code>IMPORTANT TO KNOW</code> the import process will little bit slow!<br />
                                Type of salary component : <code>T1=Monthly</code>, <code>T2=Daily</code>
                            </div>
                            {roleName === allowedRole && <Row>
                                <Col sm="12">
                                    <Components.Select
                                        label="Company"
                                        name="companyIdForSalary"
                                        defaultValue={companyIdForSalary}
                                        change={updatePayload}
                                        options={companies}
                                    />
                                </Col>
                            </Row>}
                            <hr />
                            <p>Upload your import file in here (.xls, .xlsx):</p>
                            <Row>
                                <Col sm="12">
                                    <input type="file" name="file" className="form-control" onChange={(e) => handleFileUpload(e, 'salary')} />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={downloadTemplateSalaryComponent}>
                                Download Template
                            </Button>
                            <Button variant="primary" onClick={doImportSalaryComponent}>
                                Upload Data
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Modal update PPH */}
                    <Modal show={showPPH} onHide={closeUpdatePPHModal} size="md">
                        <Modal.Header closeButton>
                            <Modal.Title>Update PPH Type</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="alert alert-info">
                                In this form, you will update <code>PPH Type</code> for <strong>All Employees</strong> by the company you selected.<br />
                                [Make sure you know what will you do]
                            </div>
                            <Row>
                                {roleName === allowedRole && <Col sm="6">
                                    <Components.Select
                                        label="Company"
                                        name="companyInModal"
                                        defaultValue={companyInModal}
                                        change={updatePayload}
                                        options={companies}
                                    />
                                </Col>}
                                <Col sm="6">
                                    <Components.Select
                                        label="PPH Type"
                                        name="pph_type"
                                        defaultValue={pphType}
                                        options={pphTypes}
                                        change={updatePayload}
                                    />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={doUpdatePPH}>
                                Save changes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Modal import employee */}
                    <Modal show={show} onHide={closeImportModal} size="xl">
                        <Modal.Header closeButton>
                            <Modal.Title>Import Data</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="alert alert-info">
                                Codes below just for informations that you need to fill in <code>excel</code> file before import.<br />
                                [NOT REQUIRED]
                            </div>
                            <legend>
                                Code informations
                            </legend>
                            <Row>
                                {roleName === allowedRole && <Col sm="4">
                                    <Components.Select
                                        label="Company"
                                        name="companyInModal"
                                        defaultValue={companyInModal}
                                        change={updatePayload}
                                        options={companies}
                                    />
                                </Col>}
                                <Col sm="4">
                                    <Components.Select
                                        label="Marital Status"
                                        name="maritalStatus"
                                        options={maritalStatus}
                                        noInitOption={true}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Select
                                        label="PTKP"
                                        name="ptkp"
                                        options={ptkp}
                                        noInitOption={true}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Select
                                        label="Salary Type"
                                        name="salary_type"
                                        options={salaryTypes}
                                        noInitOption={true}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Select
                                        label="PPH Type"
                                        name="pph_type"
                                        options={pphTypes}
                                        noInitOption={true}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Select
                                        label="Department"
                                        name="department_code"
                                        options={departmentCodes}
                                        noInitOption={true}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Select
                                        label="Position"
                                        name="position_code"
                                        options={positionCodes}
                                        noInitOption={true}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Select
                                        label="Employee Type"
                                        name="employee_type"
                                        options={employeeTypeCodes}
                                        noInitOption={true}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Select
                                        label="Bank Code"
                                        name="bank_code"
                                        options={bankCodes}
                                        noInitOption={true}
                                    />
                                </Col>
                            </Row>
                            <hr />
                            <h4>Upload your import file in here (.xls, .xlsx):</h4>
                            <Row>
                                <Col sm="4">
                                    <input type="file" name="file" className="form-control" onChange={handleFileUpload} />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={downloadTemplate}>
                                Download Template
                            </Button>
                            <Button variant="primary" onClick={doImport}>
                                Upload Data
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <br /><br />
                    <Grid
                        className={{ table: 'table table-bordered mb-0' }}
                        resizable={true}
                        language={{
                            search: {
                                placeholder: 'Find something..'
                            }
                        }}
                        columns={[
                            'ID Employee',
                            'Name',
                            'Phone',
                            'Department',
                            'Position',
                            'Action',
                        ]}
                        server={{
                            url: `${rest.MAIN_HOST}${endpoint}${companyId !== '' && companyId !== null && companyId !== undefined ? '?companyId=' + companyId : ''}`,
                            headers: {
                                'authorization': `Bearer ${rest.ACCESS_TOKEN}`
                            },
                            then: data => data?.data?.data?.map(r => [
                                r.nik,
                                `${r.first_name} ${r.middle_name ?? ''} ${r.last_name ?? ''}`,
                                r.phone,
                                (r?.employee_job_histories[0]?.departments?.name !== undefined ? r.employee_job_histories[0].departments.name : '-'),
                                (r?.employee_job_histories[0]?.positions?.name !== undefined ? r.employee_job_histories[0].positions.name : '-'),
                                _(
                                    <div style={{ textAlign: 'center' }}>
                                        <Dropdown className="btn-group">
                                            <Dropdown.Toggle variant="success" className="btn-sm text-white">
                                                <i className="ri-menu-5-line"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => goToPage(r.id, 'edit')}>Edit</Dropdown.Item>
                                                <Dropdown.Item onClick={() => goToPage(r.id, 'salary_components')}>Salary Components</Dropdown.Item>
                                                <Dropdown.Item onClick={() => goToPage(r.id, 'reset_device')}>Reset Device</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        &nbsp;
                                        <button type="button" onClick={() => removeItem(r.id)} className="btn btn-danger btn-sm" title="Remove data"><i className="ri-delete-bin-line"></i></button>
                                    </div>
                                )
                            ]),
                            total: data => data?.data?.total?.data,
                            handle: (res) => {
                                if (res.status === 401) return rest.interceptor();
                                return res.json();
                            }
                        }}
                        search={{
                            server: {
                                url: (prev, keyword) => `${prev}?keywords=${keyword}`
                            }
                        }}
                        pagination={{
                            limit: 10,
                            server: {
                                url: (prev, page, limit) => {
                                    const operator = prev.includes('?') ? '&' : '?';
                                    if (parseInt(page) <= 0) page = 1;
                                    return `${prev}${operator}limit=${limit}&page=${page}`
                                }
                            }
                        }}
                    />
                </Card.Body>
            </Card>
        </div>
    )

}

export default EmployeeList;